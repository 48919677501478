/* eslint-disable @typescript-eslint/naming-convention */
import { createBrowserHistory } from 'history'
import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { analytics } from './Modules/Analytics'
import * as serviceWorker from './serviceWorker'
import Auth0ProviderWithHistory from './Auth/Auth0ProviderWithHistory'
import handleFavicons from './Modules/handleFavicons'

const history = createBrowserHistory()

// This is an early loader that doesn't bundle any dependencies like MUI or Birdsong
const AppLoading = (
  <div
    style={{ height: '100vh', width: '100vw', backgroundColor: '#FAF8F5' }}
  />
)

// Lazy loading the bulk of the app, and its dependencies so that Auth0 can authorize the user in parallel (ton increase performance)
const App = lazy(() => import('./Components/App/App'))

analytics.init()
handleFavicons()
Sentry.init({
  dsn: window._env_.sentryDsn,
  environment: 'client',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    Sentry.replayIntegration(),
  ],

  release: '0.0.1',

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // Learn more at
  // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
  tracesSampleRate: window._env_.sentryTracesSampleRate,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [/^\//, /^https:\/\/resilia\.com/, 'localhost'],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  // Learn more at
  // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
  replaysSessionSampleRate: window._env_.sentryReplaysSessionSampleRate,
  replaysOnErrorSampleRate: window._env_.sentryReplaysOnErrorSampleRate,
})
try {
  // Storing the initial pathname prior to loading the app in order
  // to determine the first page load for performance analytics
  window.sessionStorage.setItem('initial-pathname', window.location.pathname)
} catch (e) {
  // Don’t let performance analytics crash the app
}

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <Auth0ProviderWithHistory>
        <Suspense fallback={AppLoading}>
          <App />
        </Suspense>
      </Auth0ProviderWithHistory>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
